<template>
  <div class="mb-10">
    <div class="row mb-4" v-if="hasPeriod">
      <b-input-group class="col p-0 mr-3" :prepend="$t('FILTER.PERIOD.YEAR')">
        <b-form-select
          v-model="period.year"
          :options="date.years"
        ></b-form-select>
      </b-input-group>
      <b-input-group class="col p-0 mr-3" :prepend="$t('FILTER.PERIOD.MONTH')">
        <b-form-select
          v-model="period.month"
          :options="date.months"
          @change="onChangeMonth"
        ></b-form-select>
      </b-input-group>
      <b-input-group
        v-if="hasDay"
        class="col p-0"
        :prepend="$t('FILTER.PERIOD.DAY')"
      >
        <b-form-select
          v-model="period.day"
          :options="date.days"
        ></b-form-select>
      </b-input-group>
    </div>
    <div class="row">
      <!-- Period -->
      <!-- <b-input-group
        v-if="hasPeriod"
        data-app
        :prepend="$t('FILTER.PERIOD_LABEL')"
        class="col-3 p-0 mr-3"
      >
        <v-autocomplete
          class="input-group custom-select"
          :items="periods"
          autocomplete="not-today"
          auto-select-first
          solo
          flat
          dense
          @input="onInputPeriod"
          v-model="period"
        ></v-autocomplete>
      </b-input-group> -->

      <!-- RENEWABLE ENERGY -->
      <!-- Location name -->
      <b-input-group
        v-if="hasLocation"
        data-app
        :prepend="$t('FILTER.RENEWABLE_LOCATION_LABEL')"
        class="col-6 p-0 mr-3 pb-0"
      >
        <v-autocomplete
          class="input-group custom-select"
          v-model="location"
          :items="solarPlantLocations"
          autocomplete="not-today"
          item-text="name"
          solo
          flat
          dense
          return-object
        ></v-autocomplete>
      </b-input-group>

      <!-- CUI -->
      <b-input-group
        v-if="hasCompanyDetails"
        data-app
        :prepend="$t('FILTER.COMPANY_CUI_LABEL')"
        class="col p-0 mr-3 pb-0"
      >
        <v-autocomplete
          class="input-group custom-select"
          v-model="company"
          :items="organizations"
          autocomplete="not-today"
          item-text="cui"
          solo
          flat
          dense
          return-object
          @change="onChangeCompany"
        ></v-autocomplete>
      </b-input-group>

      <!-- Company name -->
      <b-input-group
        v-if="hasCompanyDetails"
        data-app
        :prepend="$t('FILTER.COMPANY_NAME_LABEL')"
        class="col p-0 pb-0"
      >
        <v-autocomplete
          class="input-group custom-select"
          v-model="company"
          :items="organizations"
          autocomplete="not-today"
          item-text="name"
          solo
          flat
          dense
          return-object
          @change="onChangeCompany"
        ></v-autocomplete>
      </b-input-group>

      <p v-if="hasCompanyDetails && hasGroups" class="mx-2 my-auto">
        {{ $t("FILTER.OR") }}
      </p>

      <!-- Group -->
      <b-input-group
        v-if="hasCompanyDetails && hasGroups"
        data-app
        :prepend="$t('FILTER.GROUP_LABEL')"
        class="col p-0 pb-0"
      >
        <v-autocomplete
          class="input-group custom-select"
          v-model="group"
          :items="groups"
          autocomplete="not-today"
          item-text="group"
          solo
          flat
          dense
          return-object
          @change="onChangeGroup"
        ></v-autocomplete>
      </b-input-group>
    </div>

    <div class="row mt-5" v-if="hasCompanyDetails"></div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import EnergyService from "@/core/services/energy.service";
import "moment/locale/ro";

export default {
  name: "EnergyFilter",
  components: {},
  props: {
    isConsumption: Boolean,
    isGeneration: Boolean,
    isGas: Boolean,
    hasCompanyDetails: Boolean,
    hasPeriod: Boolean,
    hasLocation: Boolean,
    hasRegionCounty: Boolean,
    hasGroups: {
      type: Boolean,
      default: true,
    },
    hasDay: {
      type: Boolean,
      default: true,
    },
    isRequiredMonth: {
      type: Boolean,
      default: false,
    },
    isRequiredDay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      type: "",
      company: {},
      energyTypes: [],
      location: "",
      organizations: [],
      solarPlantLocations: [],
      groups: [],
      group: {},
      date: {
        years: [],
        months: [],
        days: [],
      },
      period: {
        year: "",
        month: "",
        day: "",
      },
    };
  },
  methods: {
    getLanguage() {
      let language = localStorage.getItem("language");
      language = language === "ro" ? "ro" : "en-US";
      return language;
    },
    onChangeCompany() {
      this.group = {};
    },
    onChangeGroup() {
      this.company = {};
    },
    onChangeMonth() {
      this.date.days = this.isRequiredDay ? [] : [""];
      if (this.period.month.length > 0) {
        let nrDays = this.$moment(
          `${this.period.year}-${this.period.month}`,
          "YYYY-MM"
        ).daysInMonth();
        for (let i = 1; i <= nrDays; i++) {
          this.date.days.push(i + "");
        }
      }

      this.period.day = this.date.days[0];

      if (this.isConsumption && this.isGas) {
        this.getGasCompanies();
      }
    },
    getEnergyCompanies() {
      ApiService.post("/companies/energy").then((response) => {
        this.organizations = response.data;
        this.company = this.organizations[0];
      });
    },
    getGasCompanies() {
      this.organizations = [];

      let granularity = "month";
      if (this.period.month.length > 0) {
        granularity = "day"; // in backend month is day actually
      }

      ApiService.post(`/companies/gas/${granularity}`).then((response) => {
        this.organizations = response.data;
        this.company = this.organizations[0];
      });
    },
    getSolarPlantLocations() {
      ApiService.post("/solar/plants").then((response) => {
        this.solarPlantLocations = response.data;
        this.location = this.solarPlantLocations[0];
      });
    },
    async getGroups() {
      let response = await EnergyService.getCompanyGroups(
        -1,
        -1,
        this.isGas ? 1 : 0,
        ""
      );
      this.groups = response.items;
    },
  },
  mounted() {
    this.$moment.locale(this.getLanguage());

    let currentYear = this.$moment().year();
    for (let i = currentYear; i > currentYear - 20; i--) {
      this.date.years.push(i + "");
    }

    this.period.year = this.date.years[0];

    let months = this.$moment().localeData().months();
    this.date.months = this.isRequiredMonth ? [] : [{ value: "", text: "" }];
    for (let i = 0; i < months.length; i++) {
      this.date.months.push({ value: "" + (i + 1), text: months[i] });
    }

    this.period.month = this.date.months[0].value;
    this.onChangeMonth();

    if (this.isConsumption) {
      if (this.isGas) this.getGasCompanies();
      else this.getEnergyCompanies();

      this.getGroups();
    }

    if (this.isGeneration) {
      this.getSolarPlantLocations();
    }
  },
};
</script>

<style>
.v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle,
.v-select__slot,
.v-data-table .text-start {
  font-size: 1rem !important;
  line-height: 1.1rem !important;
  font-weight: normal !important;
}

.v-input,
.v-label,
.v-list-item__title,
.v-data-footer,
.v-data-footer__select .v-select__selections .v-select__selection--comma {
  font-size: 1.1rem !important;
  font-weight: normal !important;
}
.v-toolbar__content,
.v-data-footer {
  padding: 0px !important;
}
</style>
